let routeName = 'module->Accounting->Client';

export const module = {
    info: {
        routeName,
        name: 'Client',
        caption: 'Client',
        namespace: 'Accounting',
        fullName: 'Accounting-Client'
        // viewGroup: 'general-modules'
    },
    vuexModules: ['api'],
    sideMenu: {
        notAllowedProfiles: [],
        topSeparator: false,
        caption: 'Client',
        icon: 'el-icon-folder',
        routeName: 'list->page',
        routeParams: {listPage: 1}
        /*children: [
          {
            caption: 'Create Entity',
            icon: 'el-icon-plus',
            routeName: 'create'
          },
          {
            caption: 'List Entities',
            icon: 'el-icon-more-outline',
            routeName: 'list->page',
            routeParams: { listPage: 1}
          }
        ]*/
    },
    onLoad: async function () {
        $xapi = {
            ...$xapi,
            ...$mapActions('Accounting-Client-api', {
                getAccountingClientDropdown: 'getDropDownList',
                getAccountingClient: 'get',
                getAccountingClientList: 'list',
                getAccountingClientListAll: 'listAll',
                getAccountingClientStatistics: 'getStatistics',
                accountingClientCheckDuplicateFields: 'checkDuplicateFields'
            }),
        }

        $services['NumUtil'] = {
            rmNonDigits: function (v) {
                if (v) return v.toString().replace(/[^\d.-]/g, '')
                return ''
            },
            NumberFormat: new Intl.NumberFormat('en-CA', {
                style: 'currency',
                currency: 'CAD',
                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }),
            format: function (v) {
                return this.NumberFormat.format(this.rmNonDigits(v))
            }
        }
        return 0
    },
    router: {
        mainRouteName: 'portal-cpanel',
        routes: {
            path: 'Client',
            name: routeName,
            component: () => import('./View.vue'),
            children: [
                {
                    path: 'quick-view',
                    name: routeName + '->quick-view',
                    component: () => import('./quick-view/View')
                },
                {
                    path: 'create',
                    name: routeName + '->create',
                    component: () => import('./create/View')
                },
                {
                    path: 'list',
                    name: routeName + '->list',
                    component: () => import('./list/View'),
                    children: [
                        {
                            path: ':listPage',
                            name: routeName + '->list->page',
                            component: () => import('./list/page/View'),
                        }
                    ]
                },
                {
                    path: 'labels-page',
                    name: routeName + '->labels-page',
                    component: () => import('./labels-page/View'),
                },
                {
                    path: ':clientId',
                    name: routeName + '->view',
                    component: () => import('./create/View')
                }
                /*,
                  {
                    path: 'external-component',
                    name: routeName + '->external-component',
                    component: () => $hkms.loadExternalComponent('http://127.6.3.1:8000/xapi/UI/ModuleManager/getComponent/Namespace/ComponentName'),
                  }*/
            ]
        }
    }
}

export default module
